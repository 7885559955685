import { combineReducers } from "redux"
import { createBookingReducer } from "slice/createBookingSlice"
import { examinerReducer } from "slice/examinerSlice"
import { languageReducer } from "slice/languageSlice"
import { notificationReducer } from "slice/notificationSlice"
import { submissionReducer } from "slice/submissionSlice"
import { tableReducer } from "slice/tableSlice"
import { transactionReducer } from "slice/transactionSlice"
import { treatmentsReducer } from "slice/treatmentsSlice"
import { userReducer } from "slice/userSlice"

export default combineReducers({
  user: userReducer,
  examiner: examinerReducer,
  treatment: treatmentsReducer,
  createBooking: createBookingReducer,
  submission: submissionReducer,
  notification: notificationReducer,
  language: languageReducer,
  table: tableReducer,
  transaction: transactionReducer,
})
