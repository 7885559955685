import { createSlice } from "@reduxjs/toolkit"

const transactionSlice = createSlice({
  name: "transaction",
  initialState: {
    selectedTransaction: null,
    numberOfWaitingTransaction: 0,
  },
  reducers: {
    setSelectedTransaction: (state, action) => {
      state.selectedTransaction = action.payload
    },
    setNumberOfWaitingTransaction: (state, action) => {
      state.numberOfWaitingTransaction = action.payload
    },
  },
})

export const {
  reducer: transactionReducer,
  actions: { setSelectedTransaction, setNumberOfWaitingTransaction },
} = transactionSlice
