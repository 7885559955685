import axios from "../axios"
import qs from "qs"

export const getTransactions = (pagination, filters) => {
  const query = qs.stringify({
    filters,
    populate: "*",
    pagination,
    sort: ["createdAt:DESC"],
  })
  return axios.get(`/transactions?${query}`)
}

export const updateTransaction = (id, data) => {
  const query = qs.stringify({
    populate: "*",
  })
  return axios.put(`/transactions/${id}?${query}`, {
    data,
  })
}
