import { createSlice } from "@reduxjs/toolkit"

const submissionSlice = createSlice({
  name: "submission",
  initialState: {
    filters: {
      selectedSubmisison: null,
      numberOfWaitingSubmission: 0,
    },
  },
  reducers: {
    setSelectedSubmission: (state, action) => {
      state.selectedSubmisison = action.payload
    },
    setNumberOfWaitingSubmission: (state, action) => {
      state.numberOfWaitingSubmission = action.payload
    },
  },
})

export const {
  reducer: submissionReducer,
  actions: { setSelectedSubmission, setNumberOfWaitingSubmission },
} = submissionSlice
