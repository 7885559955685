import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import Sidebar from "components/Sidebar"
import { JWT_TOKEN, USER_ROLE } from "constants/Authentication"
import { getMe } from "services/api/users"
import { setCurrentUser } from "slice/userSlice"
import { getErrorMessage } from "utils/error"

const MainLayout = ({ children }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    ;(async () => {
      try {
        const userRes = await getMe()
        if (![USER_ROLE.PUBLIC, USER_ROLE.AUTHENTICATED].includes(userRes?.data?.role?.type)) {
          dispatch(setCurrentUser(userRes.data))
        } else {
          localStorage.removeItem(JWT_TOKEN)
          throw new Error(t("errors.missing_permission_access"))
        }
      } catch (error) {
        if (error?.response?.status === 401) {
          dispatch(setCurrentUser(null))
          localStorage.removeItem(JWT_TOKEN)
          navigate("/login")
        }
        toast.error(getErrorMessage(error))
      }
    })()
  }, [dispatch, navigate])

  return (
    <div className="flex">
      <Sidebar />
      <div className="flex-1 overflow-x-hidden">{children}</div>
    </div>
  )
}

export default MainLayout
