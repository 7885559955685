export const SIDEBAR_ITEMS = [
  {
    id: "contestant",
    name: "contestant",
    icon: "sidebar/user",
    url: "/thi-sinh",
    apiKey: "plugin::users-permissions",
    controllerKey: "user",
  },
  {
    id: "submission",
    name: "submission",
    icon: "sidebar/submission",
    url: "/bai-du-thi",
    apiKey: "api::submission",
    controllerKey: "submission",
    showBadge: true,
  },
  {
    id: "community_examiner",
    name: "community_examiner",
    icon: "sidebar/people",
    url: "/giam-khao-binh-chon",
    apiKey: "api::community-examiner",
    controllerKey: "community-examiner",
  },
  // {
  //   id: "sponsor",
  //   name: "sponsor",
  //   icon: "sidebar/award",
  //   url: "/nha-tai-tro",
  //   apiKey: "api::sponsor",
  //   controllerKey: "sponsor",
  // },
  {
    id: "blogs",
    name: "blogs",
    icon: "sidebar/blogs",
    url: "/bai-viet",
    subItems: [{ name: "blog.blog_category", url: "/danh-muc-bai-viet" }],
    apiKey: "api::blog",
    controllerKey: "blog",
  },
  // {
  //   id: "transaction",
  //   name: "transaction",
  //   icon: "sidebar/receipt",
  //   url: "/giao-dich",
  //   apiKey: "api::transaction",
  //   controllerKey: "transaction",
  //   showBadge: true,
  // },
  {
    id: "settings",
    name: "settings",
    icon: "sidebar/settings",
    url: "/cai-dat",
    apiKey: "plugin::users-permissions",
    controllerKey: "role",
  },
]
