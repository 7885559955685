import { createSlice } from "@reduxjs/toolkit"

const examinerSlice = createSlice({
  name: "examiner",
  initialState: {
    selectedRole: "giam-khao-binh-chon",
  },
  reducers: {
    setSelectedRole: (state, action) => {
      state.selectedRole = action.payload
    },
  },
})

export const {
  reducer: examinerReducer,
  actions: { setSelectedRole },
} = examinerSlice
