import axios from "../axios"
import qs from "qs"

const populate = [
  "images",
  "ideaIntroduction",
  "legalCommitment",
  "ownershipCommitment",
  "documents",
  "user",
  "scores",
  "scores.user",
  "transactions",
  "brandIntroduction",
  "categories",
  "categories.parent",
  "categories.parent.parent",
  "mediaTypes",
  "voices",
  "votes",
  "votes.examiner",
  "votes.examiner.avatar",
]

export const getSubmissions = (pagination, filters) => {
  const query = qs.stringify({
    filters,
    populate,
    pagination,
    sort: ["createdAt:DESC"],
  })
  return axios.get(`/submissions?${query}`)
}

export const getSubmissionsForReport = (pagination, filters) => {
  const query = qs.stringify({
    filters,
    populate: ["user", "transactions", "categories", "categories.parent", "mediaTypes", "votes"],
    pagination,
    sort: ["createdAt:ASC"],
  })
  return axios.get(`/submissions?${query}`)
}

export const updateSubmission = (id, data) => {
  const query = qs.stringify({
    populate,
  })
  return axios.put(`/submissions/${id}?${query}`, {
    data,
  })
}

export const getSubmissionById = (id) => {
  const query = qs.stringify({
    populate,
    publicationState: "preview",
  })
  return axios.get(`/submissions/${id}?${query}`)
}

export const createSubmission = (data) => {
  return axios.post(`/submissions`, {
    data,
  })
}

export const getSubmissionCategories = (publicationState = "live") => {
  const query = qs.stringify({
    pagination: {
      perPage: 100,
    },
    populate: ["parent", "parent.parent", "name", "slug"],
    publicationState,
  })
  return axios.get(`/submission-categories?${query}`)
}
