import React from "react"
import { useTranslation } from "react-i18next"
import { Navigate, Route, Routes } from "react-router-dom"

const Settings = React.lazy(() => import("pages/Settings"))
const RoleSetting = React.lazy(() => import("pages/Settings/RoleSetting"))
const Report = React.lazy(() => import("pages/Settings/Report"))
const Homepage = React.lazy(() => import("pages/Settings/Homepage"))

const Blogs = React.lazy(() => import("pages/Blogs"))
const BlogCategories = React.lazy(() => import("pages/BlogCategories"))
const UpsertBlog = React.lazy(() => import("pages/Blogs/UpsertBlog"))

const Contestant = React.lazy(() => import("pages/Contestant"))
const Sponsor = React.lazy(() => import("pages/Sponsor"))
const Submission = React.lazy(() => import("pages/Submission"))
const UpsertSubmission = React.lazy(() => import("pages/Submission/UpsertSubmission"))
const Transaction = React.lazy(() => import("pages/Transaction"))
const CommunityExaminer = React.lazy(() => import("pages/CommunityExaminer"))
const EditCommunityExaminer = React.lazy(() =>
  import("pages/CommunityExaminer/UpsertCommunityExaminer")
)
const Theme = React.lazy(() => import("pages/Theme"))

const MainRoutes = () => {
  const { t } = useTranslation()

  return (
    <React.Suspense fallback={<span>{t("loading")}</span>}>
      <Routes>
        <Route path="/" element={<Navigate to="/bai-du-thi" />} />
        <Route path="/cai-dat" element={<Settings />} />
        <Route path="/cai-dat/vai-tro" element={<RoleSetting />} />
        <Route path="/cai-dat/bao-cao" element={<Report />} />
        <Route path="/cai-dat/trang-chu" element={<Homepage />} />

        <Route path="/bai-viet" element={<Blogs />} />
        <Route path="/bai-viet/tao-moi" element={<UpsertBlog />} />
        <Route path="/bai-viet/:id/chinh-sua" element={<UpsertBlog />} />
        <Route path="/danh-muc-bai-viet" element={<BlogCategories />} />
        <Route path="/bai-du-thi" element={<Submission />} />
        <Route path="/bai-du-thi/tao-moi" element={<UpsertSubmission />} />
        <Route path="/bai-du-thi/:id/chinh-sua" element={<UpsertSubmission />} />
        <Route path="/giao-dich" element={<Transaction />} />
        <Route path="/thi-sinh" element={<Contestant />} />
        <Route path="/nha-tai-tro" element={<Sponsor />} />
        <Route path="/giam-khao-binh-chon" element={<CommunityExaminer />} />
        <Route path="/giam-khao-binh-chon/tao-moi" element={<EditCommunityExaminer />} />
        <Route path="/giam-khao-binh-chon/:id/chinh-sua" element={<EditCommunityExaminer />} />
        <Route path="/theme" element={<Theme />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </React.Suspense>
  )
}

export default MainRoutes
