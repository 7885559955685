import { createSlice } from "@reduxjs/toolkit"

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    selectedUser: null,
    staffRoles: [],
  },
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload
    },
    setSelectedUser: (state, action) => {
      state.selectedUser = action.payload
    },
    setStaffRoles: (state, action) => {
      state.staffRoles = action.payload || []
    },
  },
})

export const {
  reducer: userReducer,
  actions: { setCurrentUser, setStaffRoles, setSelectedUser },
} = userSlice
